@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  scroll-behavior: smooth;
}
/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #808080;
}

/* Chrome, Edge and Safari */
*::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
*::-webkit-scrollbar-track {
  border-radius: 20px;
  background-color: #0000;
}

*::-webkit-scrollbar-track:hover {
  background-color: #0000;
}

*::-webkit-scrollbar-track:active {
  background-color: #0000;
}

*::-webkit-scrollbar-thumb {
  background-color: #808080;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #808080;
}

*::-webkit-scrollbar-thumb:active {
  background-color: #808080;
}
